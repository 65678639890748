import React from 'react'
import Navbar from '../../PagesComponents/Main/Navbar';
import NewsLetter from '../../PagesComponents/Components/NewsLetter';
import Footer from '../../PagesComponents/Components/Footer';
import ImageLazyLoading from '../../PagesComponents/Components/ImageLazyLoading';

function Offers() {
  document.title = "Ofertas de produtos";
  return (
    <div>
      <Navbar Active={3} />
       <div className="offers-page">
           <br />
             <div className="center" >
              <div className="title">Ofertas de produtos e serviços</div>
                 <div className="wrap-box">
                    <div className="box"><ImageLazyLoading source={require("../../Assets/Images/img1.png")} height={350} /></div> 
                    <div className="box"><ImageLazyLoading source={require("../../Assets/Images/img2.png")} height={350} /></div> 
                    <div className="box"><ImageLazyLoading source={require("../../Assets/Images/img3.png")} height={350} /></div> 
                   
                  </div> 
              </div>
           <br />
       </div>
      <NewsLetter />
      <Footer/>
   </div>
  )
}

export default Offers