import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import DashboardWrapper from './Components/DashboardWrapper';
import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { MdDeleteOutline } from "react-icons/md";
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { IoAddCircleOutline } from "react-icons/io5";
import ImageLazyLoading from "../PagesComponents/Components/ImageLazyLoading";
import { FiPlus } from "react-icons/fi";
import { useEffect } from 'react';
import axios from 'axios';
import ServerUrl from '../Pages/ServerUrl';
import { toast } from 'react-toastify';
import { FaRegTrashAlt } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { useState } from 'react';



function EnhancedTable() {
    const [rows, setRows] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [selectedImages, setSelectedImages] = React.useState([]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [SelectedProduct, setSelectedProduct] = React.useState(null);
    const [SelectedProductCode, setSelectedProductCode] = React.useState(null);
    const [SelectedProductImages, setSelectedProductImages] = React.useState([]);

    const [productName, setProductName] = React.useState(null);
    const [productCategorie, setProductCategorie] = React.useState(null);
    const [productSubCategorie, setProductSubCategorie] = React.useState(null);
    const [ProductSerie, setProductSerie] = React.useState(null);
    const [ProductPrice, setProductPrice] = React.useState(null);
    const [ProductPrice2, setProductPrice2] = React.useState(null);
    const [ProductBrand, setProductBrand] = React.useState(null);
    const [ProductDescription, setProductDescription] = React.useState(null);
    const [LoadedData, setLoadedData] = React.useState({ series: [], categories: [], subcategories: [] });


    const [ProductImage, setProductImage] = React.useState(null);

    function AddImage() {
        if (ProductImage !== null) {
            setSelectedImages(prev => [...prev, ProductImage]);
            setProductImage(null);
        }
    }


    const columns = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Nome',
        },
        {
            id: 'price',
            numeric: true,
            disablePadding: false,
            label: 'Preço',
        },
        {
            id: 'brand',
            numeric: true,
            disablePadding: false,
            label: 'Marca',
        },
        {
            id: 'action',
            numeric: true,
            disablePadding: false,
            label: 'Ações',
        },
    ];



    async function UpdateData(e) {
        e.preventDefault();
        const INPUTS = {
            name: productName,
            price: ProductPrice,
            oldprice: ProductPrice2,
            description: ProductDescription,
            category: productCategorie,
            subcategorie: productSubCategorie,
            brand: ProductBrand,
            serie: ProductSerie,
        };

        try {
            if (productName !== null && productCategorie !== null && ProductBrand !== null && ProductDescription !== null && SelectedProductCode !== null) {
                let response = await axios.post(ServerUrl() + "htmarketupdateproduct/" + SelectedProductCode, INPUTS);
                setProductDescription(null);
                setProductName(null);
                setSelectedImages([]);

                if (selectedImages.length > 0) {
                    for (let i = 0; i < selectedImages.length; i++) {
                        const img = selectedImages[i];
                        let addImage = await axios.post(ServerUrl() + "htmarketuploadimages", { name: img, code: SelectedProductCode });
                        console.log(addImage.data);
                        if (i + 1 === selectedImages.length) {
                            getProducts()
                            toast.success('Produto atualizado com sucesso', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    }
                } else {
                    getProducts()
                    toast.success('Produto atualizado com sucesso', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } else { 
                toast.error('Preencha corretamente os campos ! ', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) { 
            console.clear()
            console.clear(INPUTS);
            console.log(error)

        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    async function deleteProduct(id) {
        try { 
            await axios.post(ServerUrl() + "htmarketdeleteproduct/" + id);
            toast.success('Produto deletado com sucesso', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            getProducts();
        } catch (error) {
            console.clear();
            console.log(error);
            toast.error('Lamentamos mas não foi possivel deletar este produto !', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }


    async function DeleteProductImage(id, code) {
        try {
            await axios.post(ServerUrl() + "htmarketdeleteimage/" + id);
            toast.success('Imagem deletada com sucesso', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            loadSelectedProductImages(code);
        } catch (error) {
            console.clear();
            console.log("printing error ....");

            console.log(error);
            toast.error('Lamentamos mas não foi possivel deletar está imagem !', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    async function getProducts() {
        try {
            let response = await axios.get(ServerUrl() + "htmarketgetallelements");
            console.clear()
            console.log("#####################################");
            setLoadedData(response.data)
            console.log("#####################################");

        } catch (error) {
            console.clear()
            console.log(error)
        }

        try {
            let response = await axios.get(ServerUrl() + "htmarketgetallproducts/");
            let products = []
            response.data.d1.forEach(el => {
                let images = [];
                response.data.d2.forEach(img => {
                    if (img.file_product_code === el._id) images.push(img);
                });
                products.push({ content: el, images: images });
            });
            const rows1 = [];
            products.map((item) => {
                rows1.push({
                    id: item.content._id,
                    name: <div className="product-table-item">
                        <ImageLazyLoading source={item.images.length > 0 ? item.images[0].file_name : "https://ralfvanveen.com/wp-content/uploads/2021/06/Placeholder-_-Glossary.svg"} />
                        <strong className="ml-2">{item.content.product_name}</strong>
                    </div>,
                    price: <strong>{item.content.product_price === null ?
                        <small className="text-main">Preço não inserido</small>
                        : item.content.product_price + " €" + ((Math.floor(item.content.product_oldprice*1) > 0) ? " - "+Math.floor(item.content.product_oldprice*1)+" €" : "")}
                    </strong>,
                    category: item.content.product_categorie,
                    brand: <strong>{item.content.product_brand}</strong>,
                    subcategory: item.content.product_subcategorie,
                    action: <div className="d-flex">
                        <button className="btn text-light bg-danger" onClick={() => deleteProduct(item.content._id)}><FaRegTrashAlt /></button>
                        <button className="btn text-light  bg-success" onClick={() => loadSelectedProduct(item.content._id)}><MdEdit /></button>
                    </div>
                });
            });
            setRows(rows1);
        } catch (error) {
            console.clear();
            console.log(error)
        }
    }

    async function loadSelectedProductImages(id) {
        try {
            const SingleProduct = await axios.get(ServerUrl() + "htmarketgetsingleproduct/" + id);
            setSelectedProductImages(SingleProduct.data.images);
            handleShow();
        } catch (error) {
        }
    }

    async function loadSelectedProduct(id) {
        try {
            const SingleProduct = await axios.get(ServerUrl() + "htmarketgetsingleproduct/" + id);
            setSelectedProduct({
                categorie: SingleProduct.data.categorie._id,
                content: SingleProduct.data.content,
            });

            setSelectedProductImages(SingleProduct.data.images);  
            setProductName(SingleProduct.data.content.product_name);
            setProductBrand(SingleProduct.data.content.product_brand);
            setProductPrice(SingleProduct.data.content.product_price);
            setProductPrice2(SingleProduct.data.content.product_oldprice)
            setProductCategorie(SingleProduct.data.categorie._id);
            setProductDescription(SingleProduct.data.content.product_description); 
            setSelectedProductCode(SingleProduct.data.content._id); 
            handleShow();

        } catch (error) {
            toast.error('Lamentamos mas não foi possivel carregar os dados deste produto !', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.log(error)
        }
    }




    useEffect(() => {
        getProducts();
        setTimeout(() => {
            getProducts();
        }, 3000);
    }, []);



    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Modal size='lg' show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> <strong>Editar produto </strong>  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {SelectedProduct !== null ?
                        <Form onSubmit={UpdateData}  >
                            <div className="ed-flex space">
                                <Form.Group className="mb-3 col" controlId="formBasicEmail">
                                    <Form.Label>Nome do produto  <span className='text-danger'>*</span></Form.Label>
                                    <Form.Control value={productName} onPaste={(e) => setProductName(e.target.value)} onChange={(e) => setProductName(e.target.value)} type="text" placeholder="..." />
                                </Form.Group>
                                <div className="pd"></div>
                                <Form.Group className="mb-3 col" controlId="formBasicEmail">
                                    <Form.Label>Marca <span className='text-danger'>*</span> </Form.Label>
                                    <Form.Select onChange={(e) => setProductBrand(e.target.value)} required>
                                        <option disabled >Selecionar marca</option>
                                        <option value="Comprag" selected={SelectedProduct.content.product_brand.toLowerCase() === "comprag" ? true : false} >Comprag</option>
                                        <option value="Elgi" selected={SelectedProduct.content.product_brand.toLowerCase() === "elgi" ? true : false} >Elgi</option>
                                        <option value="Infinity" selected={SelectedProduct.content.product_brand.toLowerCase() === "infinity" ? true : false} >Infinity</option>
                                        <option value="Jork" selected={SelectedProduct.content.product_brand.toLowerCase() === "jork" ? true : false} >Jork</option>
                                        <option value="Aignep" selected={SelectedProduct.content.product_brand.toLowerCase() === "aignep" ? true : false} >Aignep</option>
                                        <option value="Bigiessie" selected={SelectedProduct.content.product_brand.toLowerCase() === "bigiessie" ? true : false} >Bigiessie</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>  
                            <div className="d-flex space">
                                <Form.Group className="mb-3 col" controlId="formBasicEmail">
                                   <Form.Label>Apartir Dos (Preço)  </Form.Label>
                                    <Form.Control onPaste={(e) => setProductPrice(e.target.value)} step=".01" value={ProductPrice}
                                    onChange={(e) => setProductPrice(e.target.value)} type="number" placeholder="..." />
                                </Form.Group>
                                    <div className="pd"></div>
                                    <Form.Group className="mb-3 col" controlId="formBasicEmail">
                                    <Form.Label>Ate os (Preço)</Form.Label>
                                    <Form.Control onPaste={(e) => setProductPrice2(e.target.value)} step=".01" value={ProductPrice2}
                                        onChange={(e) => setProductPrice2(e.target.value)} type="number" placeholder="..." />
                                </Form.Group>
                                </div>
                                <div className="mb-3 col">
                                <Form.Group className="mb-3 col" controlId="formBasicEmail">
                                    <Form.Label>Categoria <span className='text-danger'>*</span> </Form.Label>
                                    <Form.Select onChange={(e) => setProductCategorie(e.target.value)} required>
                                        <option disabled  >Selecionar categoria</option>
                                        {LoadedData.categories.map((item) => (
                                            <option selected={SelectedProduct.categorie === item._id ? true : false} value={item._id}>{item.categorie_title}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                </div> 

                            <div className="d-flex space">
                                <Form.Group className="mb-3 col" controlId="formBasicEmail">
                                    <Form.Label>Subcategoria  </Form.Label>
                                    <Form.Select onChange={(e) => setProductSubCategorie(e.target.value)}  >
                                        <option disabled  >Selecionar subcategoria</option>
                                        {LoadedData.subcategories.map((item) => (
                                            <option value={item.subcategorie_code}    >{item.subcategorie_name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <div className="pd"></div>
                                <Form.Group className="mb-3 col" controlId="formBasicEmail">
                                    <Form.Label>Série</Form.Label>
                                    <Form.Select onChange={(e) => setProductSerie(e.target.value)}>
                                        <option disabled selected>Selecionar serie</option>
                                        {LoadedData.series.map((item) => (
                                            <option selected={SelectedProduct.content.product_serie === item._id ? true : false} value={item._id}>{item.serie_name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </div>

                            <br />

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Descrição  <span className='text-danger'>*</span></Form.Label>
                                <Form.Control value={ProductDescription} as="textarea" onPaste={(e) => setProductDescription(e.target.value)}
                                    onChange={(e) => setProductDescription(e.target.value)} rows={3} required placeholder="..." />
                            </Form.Group>
                            <br />
                            <div className="images-modal">
                                {SelectedProductImages.map((item, index) => (
                                    <article key={index} >
                                        <ImageLazyLoading source={item.file_name} height={150} />
                                        <div onClick={() => DeleteProductImage(item._id, SelectedProduct.content._id)} className="btn bg-danger mt-1 col text-light"> Deletar imagem</div>
                                    </article>
                                ))}
                            </div> 
                        </Form>


                        : <></>}
                        <div className='uploads col' >
                            <br />
                                <div className="d-flex mb-3">
                                    <Form.Group className="mb-3 col" controlId="formBasicEmail">
                                        <Form.Label> <strong>Adicionar novas Imagens do produto </strong> </Form.Label>
                                        <Form.Control type="url" onPaste={(e) => setProductImage(e.target.value)} onChange={(e) => setProductImage(e.target.value)}
                                            className='col' value={ProductImage} placeholder="Insira o link da imagem..." />
                                    </Form.Group>
                                    <div className="btn bg-black add-btn text-light ml-2" onClick={() => AddImage()} >+</div>
                                </div>
                                <div className="images-wrapper">
                                    {selectedImages.map((item) => {
                                        return (
                                            <div className="image">
                                                <ImageLazyLoading source={item} height={110} />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type='reset' onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button className='bg-main' onClick={UpdateData} type='submit'>
                        Salvar alterações
                    </Button>
                </Modal.Footer>
            </Modal>
            <TableContainer sx={{ maxHeight: 650 }}>
                <button className="btn bg-main mt-2 mb-2" onClick={()=>getProducts()} >Atualizar tabela</button>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    <strong>{column.label}</strong>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );


}

function CreateProduct() {
    const [selectedImages, setSelectedImages] = React.useState([]);
    const [LoadedData, setLoadedData] = React.useState({ series: [], categories: [], subcategories: [] });
    const [ProductPrice2, setProductPrice2] = React.useState(null);
    const [productName, setProductName] = React.useState(null);
    const [productCategorie, setProductCategorie] = React.useState(null);
    const [productSubCategorie, setProductSubCategorie] = React.useState(null);
    const [ProductSerie, setProductSerie] = React.useState(null);
    const [ProductPrice, setProductPrice] = React.useState(null);
    const [ProductBrand, setProductBrand] = React.useState(null);
    const [ProductDescription, setProductDescription] = React.useState(null);

    const [ProductImage, setProductImage] = React.useState(null);

    function AddImage() {
        if (ProductImage !== null) {
            setSelectedImages(prev => [...prev, ProductImage]);
            setProductImage(null);
        }
    }

    function makeid(length) {
        let result = Math.floor(Math.random() * 9000000069696 * length)
        return result;
    }


    async function PostData(e) {
        e.preventDefault();
        try {
            if (productName !== null && productCategorie !== null && ProductBrand !== null && ProductDescription !== null) {

                const INPUTS = {
                    name: productName,
                    price: ProductPrice,
                    oldprice: ProductPrice2,
                    description: ProductDescription,
                    category: productCategorie, 
                    subcategorie: productSubCategorie,
                    code: makeid(90),
                    brand: ProductBrand,
                    serie: ProductSerie,
                };

                let response = await axios.post(ServerUrl() + "htmarketregisternewproduct", INPUTS);
                console.clear(INPUTS);

                setProductDescription(null);
                setProductName(null);
                setSelectedImages([]);

                if (selectedImages.length > 0) {
                    for (let i = 0; i < selectedImages.length; i++) {
                        const img = selectedImages[i];
                        let addImage = await axios.post(ServerUrl() + "htmarketuploadimages", { name: img, code: response.data._id });
                        console.log(addImage.data);
                        if (i + 1 === selectedImages.length) {
                            toast.success('Produto adicionado com sucesso', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    }
                } else {
                    toast.success('Produto adicionado com sucesso', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } else { 
                toast.error('Preencha corretamente os campos !', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) { 
            console.clear()
            console.log(error)
        }
    }

    async function LoadData() {
        try {
            let response = await axios.get(ServerUrl() + "htmarketgetallelements");
            console.clear()
            console.log("#####################################");
            console.log(response.data);
            setLoadedData(response.data)
            console.log("#####################################");

        } catch (error) {
            console.clear()
            console.log(error)
        }
    }

    useEffect(() => {
        LoadData();
    }, []);


    return (
        <div>
            <DashboardWrapper active={1} title="Produtos" content={
                <section className='col'>
                    <div className="box">
                        <div className="title">Adicionar produtos</div>
                        <Form className='product-form' onSubmit={PostData} >
                            <div className='col'>
                                <div className="ed-flex space">
                                    <Form.Group className="mb-3 col" controlId="formBasicEmail">
                                        <Form.Label>Nome do produto  <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control value={productName} onPaste={(e) => setProductName(e.target.value)} onChange={(e) => setProductName(e.target.value)} type="text" placeholder="..." />
                                    </Form.Group>
                                    <div className="pd"></div>
                                    <Form.Group className="mb-3 col" controlId="formBasicEmail">
                                        <Form.Label>Marca <span className='text-danger'>*</span> </Form.Label>
                                        <Form.Select onChange={(e) => setProductBrand(e.target.value)} required>
                                            <option disabled selected>Selecionar marca</option>
                                            <option value="Comprag">Comprag</option>
                                            <option value="Elgi">Elgi</option>
                                            <option value="Infinity">Infinity</option>
                                            <option value="Jork">Jork</option>
                                            <option value="Aignep">Aignep</option>
                                            <option value="Bigiessie">Bigiessie</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className="d-flex space">
                                    <Form.Group className="mb-3 col" controlId="formBasicEmail">
                                        <Form.Label>Apartir Dos (Preço)  </Form.Label>
                                        <Form.Control onPaste={(e) => setProductPrice(e.target.value)} step=".01" onChange={(e) => setProductPrice(e.target.value)} type="number" placeholder="..." />
                                    </Form.Group>
                                    <div className="pd"></div>
                                    <Form.Group className="mb-3 col" controlId="formBasicEmail">
                                        <Form.Label>Ate os (Preço)</Form.Label>
                                        <Form.Control onPaste={(e) => setProductPrice2(e.target.value)} step=".01" onChange={(e) => setProductPrice2(e.target.value)} type="number" placeholder="..." />
                                    </Form.Group>
                                </div>
                                <div className="mb-3 col">
                                <Form.Group className="mb-3 col" controlId="formBasicEmail">
                                        <Form.Label>Categoria <span className='text-danger'>*</span> </Form.Label>
                                        <Form.Select onChange={(e) => setProductCategorie(e.target.value)} required>
                                            <option disabled selected>Selecionar categoria</option>
                                            {LoadedData.categories.map((item) => (
                                                <option value={item._id}>{item.categorie_title}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className="d-flex space">
                                    <Form.Group className="mb-3 col" controlId="formBasicEmail">
                                        <Form.Label>Subcategoria   </Form.Label>
                                        <Form.Select onChange={(e) => setProductSubCategorie(e.target.value)}  >
                                            <option disabled selected>Selecionar subcategoria</option>
                                            {LoadedData.subcategories.map((item) => (
                                                <option value={item.subcategorie_code}>{item.subcategorie_name}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <div className="pd"></div>
                                    <Form.Group className="mb-3 col" controlId="formBasicEmail">
                                        <Form.Label>Série</Form.Label>
                                        <Form.Select onChange={(e) => setProductSerie(e.target.value)}>
                                            <option disabled selected>Selecionar serie</option>
                                            {LoadedData.series.map((item) => (
                                                <option value={item._id}>{item.serie_name}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </div>

                                <br />
                                <div className="textarea-editor"></div>
                                <br />

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Descrição  <span className='text-danger'>*</span></Form.Label>
                                    <Form.Control value={ProductDescription} as="textarea" onPaste={(e) => setProductDescription(e.target.value)}
                                        onChange={(e) => setProductDescription(e.target.value)} rows={3} required placeholder="..." />
                                </Form.Group>
                                <Button className='bg-main' type="submit">
                                    Guardar
                                </Button>
                            </div>
                            <div className='uploads' >
                                <div className="d-flex mb-3">
                                    <Form.Group className="mb-3 col" controlId="formBasicEmail">
                                        <Form.Label>Imagens do produto </Form.Label>
                                        <Form.Control type="url" onPaste={(e) => setProductImage(e.target.value)} onChange={(e) => setProductImage(e.target.value)}
                                            className='col' value={ProductImage} placeholder="Insira o link da imagem..." />
                                    </Form.Group>
                                    <div className="btn bg-black text-light ml-2" onClick={() => AddImage()} >+</div>
                                </div>
                                <div className="images-wrapper">
                                    {selectedImages.map((item) => {
                                        return (
                                            <div className="image">
                                                <ImageLazyLoading source={item} height={110} />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </Form>
                    </div>
                    <br />
                    <div className="box col">
                        <div className="title">Lista de  produtos</div>
                        <EnhancedTable />
                    </div>
                </section>} />
        </div>
    )
}

export default CreateProduct