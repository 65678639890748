import { toast } from "react-toastify";
import ServerUrl from "../Pages/ServerUrl";
import axios from 'axios';
 

function ErrorMessage(msg, message) {
    if (msg !== undefined && msg !== null) {
        toast.error(msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    } else {
      toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      });
    }
}

function SuccessMessage(message) { 
    let btn = document.querySelectorAll(".d-none .rfs")
    for (let i = 0; i < btn.length; i++){ btn[i].click();}
      toast.success(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      }); 
}

export const AddProductToCart = async(code, ammount)=>{
  const CurrentUser = localStorage.getItem("useremail");
  if (CurrentUser !== null  && CurrentUser !== undefined && CurrentUser !== "null"){
    try {
      const response = await axios.post(ServerUrl() + "addnewproducttocart/"+CurrentUser, { 
          product_ammount:ammount,
          product_id:code,
      });
      SuccessMessage("Produto adicionado ao carrinho com successo !");
  } catch (error) {
      console.clear();
      console.log(error);
      ErrorMessage(error.response.data.msg  , "Lamentamos mas não foi possivel executar está ação !");
  } 
  }else{
    ErrorMessage(null  , "Precisa fazer login para adicionar o produto no carrinho !");
  }
}

export const UpdateProductAmountFromCart = async(code, amount)=>{
  const CurrentUser = localStorage.getItem("useremail");
  if (CurrentUser !== null  && CurrentUser !== undefined && CurrentUser !== "null"){
    try {
      if (amount*1 >= 1) {
        await axios.post(ServerUrl() + "updateproductammountfromcart/"+code+","+CurrentUser, {cart_ammount:amount*1});
        SuccessMessage("Dados atualizados com successo !");
      } else {
       ErrorMessage(undefined  , "O valor adicionado não é valido !");
      } 
   } catch (error) {
       ErrorMessage(error.response.data.msg  , "Lamentamos mas não foi possivel executar está ação !");
   } 
  }else{
    ErrorMessage(null  , "Precisa fazer login para atualizar o produto no carrinho !");
  }
}

export const RemoveProductFromCart = async(code)=>{
  const CurrentUser = localStorage.getItem("useremail");
  if (CurrentUser !== null  && CurrentUser !== undefined && CurrentUser !== "null"){
    try {
      await axios.delete(ServerUrl() + "removeproductfromcart/"+code+","+CurrentUser);
      SuccessMessage("Produto removido do carrinho com successo !");
  } catch (error) {
      ErrorMessage(error.response.data.msg  , "Lamentamos mas não foi possivel executar está ação !");
  } 
  }else{
    ErrorMessage(null  , "Precisa fazer login para remover o produto do carrinho !");
  }
}

export const GetAllProductsFromCart = async()=>{
  const CurrentUser = localStorage.getItem("useremail");
  let  Output  = []; 
  if (CurrentUser !== null  && CurrentUser !== undefined && CurrentUser !== "null"){
    try {
      const response = await axios.get(ServerUrl() + "getallproductsfromcart/"+CurrentUser); 

      let products = response.data.d1;
      let cartproducts = response.data.d3;
      let productsimages = response.data.d2;
      let PrWithImages = [];

        products.forEach(el => {
          let images = [];
          productsimages.forEach(img => {
            if(img.file_product_code === el._id)  images.push(img); 
          });
          PrWithImages.push({ content: el, images: images , id:el._id });
        });

        PrWithImages.forEach(el => { 
          cartproducts.forEach(cart => {
            if(cart.cart_product_prid === el.id) { 
              Output.push({ content: el.content, images: el.images , id:el.id, cart:cart , ammount:cart.cart_product_ammount, code:cart._id}); 
            }  
          }); 
        });
 
  } catch (error) {   
    Output = []
  }
  } 

    return Output;
}

export const OrderProducts = async(data)=>{
  const CurrentUser = localStorage.getItem("useremail");
  if (CurrentUser !== null  && CurrentUser !== undefined && CurrentUser !== "null"){
    try {
      if (data.length > 0) {
        await axios.post(ServerUrl() + "sendproductfromcartasorder/"+CurrentUser,{products:data});
        SuccessMessage("Parabens a sua encomenda foi solicitada, aguarde o retorno por parte da equipe comercial !");
      } else {
        ErrorMessage(undefined  , "Não existem produtos no seu carrinho !");
      }
    } catch (error) { 
        setTimeout(() => {
            console.clear()
            console.log(error)
        }, 1000);
        return [];
    } 
  }else{
    ErrorMessage(null , "Precisa fazer login para encomendar os produtos !");
  }
}