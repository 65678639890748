import React from 'react'; 
import Offcanvas from 'react-bootstrap/Offcanvas';
import { TbBasketMinus } from "react-icons/tb";
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import ProductCartComponent from '../ProductComponents/ProductCartComponent';
import { Link } from 'react-router-dom';
import { IoInformationCircle } from "react-icons/io5";
import { LuShoppingCart } from "react-icons/lu";
import { useEffect } from 'react';
import { GetAllProductsFromCart, UpdateProductAmountFromCart } from '../../../Functions/CartFunctions';


function ShoppingCartCanvas(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [Data, setData] = useState([]);

    const GetShoppingCartItems  = async()=>{
        try {
         let  data = await GetAllProductsFromCart();
         let status = Array.isArray(data);
          setData(status === true  ? data : []);
        } catch (error) {
           console.clear();
           console.log(error);
        }
    }

 

    useEffect(() => {
      GetShoppingCartItems();
    }, []);
  
  

  return (
    <>
        <div className='d-none'> <div className="rfs" onClick={()=> GetShoppingCartItems()} ></div> </div>
      <div onClick={handleShow} >
     
         <li className="cartdrop">
            <Link to="#"><LuShoppingCart /><span className='txt' >Meu carrinho</span></Link><div className="count">{Data.length > 99 ? "+99" : Data.length}</div>
        </li>
      </div>
      <Offcanvas show={show} onHide={handleClose}  placement={"end"} name={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="bold-title">Carrinho</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
           <div className="cart-canvas">
                <ul>
                    {Data.map((item, index)=>(
                         <ProductCartComponent data={item} key={index} />
                    ))}
                </ul>
                <div className="shop-details"> 
                     <Link to="/useraccount"><button className="btn col bg-black text-light">Confirmar pagamento</button></Link>
                     <Link onClick={handleClose} to="/products"><button className="btn col mt-2">Continuar a comprar</button></Link>
                </div>
           </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default ShoppingCartCanvas