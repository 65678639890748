import React, { useState } from 'react'
import Navbar from '../../PagesComponents/Main/Navbar';
import NewsLetter from '../../PagesComponents/Components/NewsLetter';
import Footer from '../../PagesComponents/Components/Footer';
import Table from 'react-bootstrap/Table';
import ImageLazyLoading from '../../PagesComponents/Components/ImageLazyLoading';
import { LogOut, Trash } from 'react-ionicons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ServerUrl, { ClientUrl } from '../ServerUrl'
import { Link, useNavigate } from "react-router-dom";
import { GoSignOut } from 'react-icons/go';
import { GetAllProductsFromCart, OrderProducts, RemoveProductFromCart, UpdateProductAmountFromCart } from '../../Functions/CartFunctions';


function Dashboard() {

    const [ActiveMenu, setActiveMenu] = useState(1);
    const navigate = useNavigate();
    const [UserData, setUserData] = useState(null);

    const [CurrentEmail, setCurrentEmail] = useState(null);
    const [CurrentName, setCurrentName] = useState(null);
    const [CurrentCharge, setCurrentCharge] = useState(null);
    const [CurrentPhone, setCurrentPhone] = useState(null);
    const [CurrentPassword, setCurrentPassword] = useState(null);
    const [NewPassword, setNewPassword] = useState(null);
    const [RepeatNewPassword, setRepeatNewPassword] = useState(null);
    const [Data, setData] = useState([]);
    const CurrentUser = localStorage.getItem("useremail");

    const GetShoppingCartItems = async () => {
        try {
            let data = await GetAllProductsFromCart();
            setData(data);
        } catch (error) {

            console.clear();
            console.log(error);
        }
    }

 

    const GetUserData = async () => {
        try {
            const response = await axios.get(ServerUrl() + "getcurrentuserdata/"+CurrentUser);
        
            setUserData(response.data);
            let e = response.data;
            setCurrentEmail(e.email);
            setCurrentName(e.username);
            setCurrentPhone(e.phone);
            setCurrentCharge(e.charge);
            document.title = "Conta Cliente - " + e.username;
            console.clear();
            console.log(response.data);

        } catch (error) {
            window.location.replace(ClientUrl()+"signin");
            console.clear();
            console.log(error)
            console.log("NOT LEGGEDIN...")
        }
    }

    const Logout = async () => {
        try {
            await axios.post(ServerUrl() + "htmarketlogout");
            localStorage.setItem("useremail", null);
            window.location.replace(ClientUrl()+"signin");
            let btn = document.querySelectorAll(".d-none .rfs")
            for (let i = 0; i < btn.length; i++){ btn[i].click();}
        } catch (error) {
            toast.error('Erro ao tentar terminar a sessão !', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const UpdateUserAccountData = async (e) => {
        e.preventDefault();
        try {
            if (CurrentName !== null && CurrentEmail !== null && CurrentName.split("").length > 5) {
                const response = await axios.post(ServerUrl() + "updatecurrentuserdata/"+CurrentUser, {
                    email: CurrentEmail.trim().toLowerCase(),
                    username: CurrentName,
                    charge: CurrentCharge === null ? "" : CurrentCharge,
                    phone: CurrentPhone === null ? "" : CurrentPhone,
                });
                setUserData(response.data);
                GetUserData();

                toast.success('Dados atualizados com sucesso !', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.error('Preencha corretamente os campos !', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            console.clear()
            console.log(error)
            if (error.response.data.msg !== undefined && error.response.data.msg !== null) {
                toast.error(error.response.data.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.error('Lamentamos mas não foi possivel executar está ação  !', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }

    const UpdateUserPassword = async (e) => {
        e.preventDefault();
        try {
            if (CurrentPassword !== null && NewPassword !== null && RepeatNewPassword !== null &&
                NewPassword === RepeatNewPassword) {
                const response = await axios.post(ServerUrl() + "updatecurrentuserpassword/"+CurrentUser, {
                    current_password: CurrentPassword,
                    new_password: NewPassword,
                });
                toast.success('Password atualizada com sucesso !', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.error('Preencha corretamente os campos !', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            console.clear()
            console.log(error)
            if (error.response.data.msg !== undefined && error.response.data.msg !== null) {
                toast.error(error.response.data.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.error('Lamentamos mas não foi possivel executar está ação  !', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }

    useEffect(() => {
       setTimeout(() => {
         GetUserData();
         GetShoppingCartItems();
       }, 1000);
    }, []);


    async function UP(A, B) {
        UpdateProductAmountFromCart(A, B);
        setTimeout(() => {
            RefreshData();
        }, 500);
    }

    function RefreshData() {
        setData([]);
        setTimeout(() => {
            async function rf() {
                let data = await GetAllProductsFromCart();
                setData(data);
                let btn = document.querySelectorAll(".rfs")
                for (let i = 0; i < btn.length; i++) {
                    btn[i].click();
                }
            }
            rf();
        }, 1000);
    }


    async function RM(A) {
        RemoveProductFromCart(A);
        setTimeout(() => {
            RefreshData();
        }, 500);
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div >
            <Navbar Active={null} />
            <div className="d-none"> <div className="rfs" onClick={()=> GetShoppingCartItems()} ></div> </div>
            {(UserData !== null && UserData.username !== null && UserData.username !== undefined) ?
                <div>
                    <div className="ml-2">
                    </div>
                    <div className="profile-account">
                        <div className="box-info">
                            <h4 className="name">{UserData.username}</h4>
                            <span>{UserData.charge} </span>
                            <button onClick={() => Logout()} className="col col-lg-12 btn bg-main mt-2"><GoSignOut color={"#ffff"} /> Terminar sessão</button>
                        </div>
                        <div className="accounts-tabs col">
                            <div className="tabs-header">
                                <div><h1 className={ActiveMenu === 1 ? "title" : "txt"} onClick={() => setActiveMenu(1)}>Dados pessoais</h1> </div>
                                <div><h1 className={ActiveMenu === 3 ? "title" : "txt"} onClick={() => setActiveMenu(3)}>Meu Carrinho</h1></div>
                            </div>
                            <br /><br />
                            <section className={ActiveMenu === 1 ? "col" : "d-none"}>
                                <Form className='col-lg-8' onSubmit={UpdateUserAccountData} >
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Nome de usúario</Form.Label>
                                        <Form.Control type="text" required
                                            onChange={(e) => setCurrentName(e.target.value)}
                                            onPaste={(e) => setCurrentName(e.target.value)}
                                            placeholder="Digite o seu nome" value={CurrentName} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Cargo ou função que ocupa na sua empresa</Form.Label>
                                        <Form.Control type="text" placeholder="Digite o seu cargo na empresa atual"
                                            onChange={(e) => setCurrentCharge(e.target.value)}
                                            onPaste={(e) => setCurrentCharge(e.target.value)}
                                            value={CurrentCharge} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Numero de telefone</Form.Label>
                                        <Form.Control type="tel"
                                            onChange={(e) => setCurrentPhone(e.target.value)}
                                            onPaste={(e) => setCurrentPhone(e.target.value)}
                                            placeholder="Digite o seu numero de telefone" value={CurrentPhone} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" required
                                            onChange={(e) => setCurrentEmail(e.target.value)}
                                            onPaste={(e) => setCurrentEmail(e.target.value)}
                                            placeholder="Digite o seu email" value={CurrentEmail} />
                                    </Form.Group>
                                    <button className="btn bg-main">Atualizar dados</button>
                                </Form>
                                <br />
                                <Form className='col-lg-8' onSubmit={UpdateUserPassword} >
                                    <h5 className='subtitle' >Mudar a password atual</h5>
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Insira  a Password atual</Form.Label>
                                        <Form.Control type="password" required
                                            onChange={(e) => setCurrentPassword(e.target.value)}
                                            onPaste={(e) => setCurrentPassword(e.target.value)}
                                            placeholder="Digite a sua senha atual" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Nova Password</Form.Label>
                                        <Form.Control type="password" min={8} minLength={8} required
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            onPaste={(e) => setNewPassword(e.target.value)}
                                            placeholder="Digite a nova password" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Repetir nova Password</Form.Label>
                                        <Form.Control type="password" min={8} minLength={8} required
                                            onChange={(e) => setRepeatNewPassword(e.target.value)}
                                            onPaste={(e) => setRepeatNewPassword(e.target.value)}
                                            placeholder="Digite novamente a nova password" />
                                    </Form.Group>
                                    <button className="btn bg-main">Atualizar password</button>
                                </Form>
                            </section>
                            <section className={ActiveMenu === 2 ? "col" : "d-none"}>
                                <h2>2</h2>
                            </section>
                            <section className={ActiveMenu === 3 ? "cart-section col" : "d-none"}> 
                                <strong className="text-main">{Data.length} produtos no carrinho</strong><br /><br />
                                <div className="flex-area">
                                    <Table striped hover>
                                        <tbody>
                                            {Data.map((item, index) => (
                                                <tr>
                                                    <td>
                                                        <div className="product-cart">
                                                            <div className="image">
                                                                <Link to={`/product_details?item=${item.content._id}`}>
                                                                    <ImageLazyLoading source={item.images.length > 0 ? item.images[0].file_name : "https://img.freepik.com/premium-vector/modern-flat-icon-landscape_203633-11062.jpg"} />
                                                                </Link>
                                                            </div>
                                                            <div className="dets">
                                                                <Link to={`/product_details?item=${item.content._id}`}>
                                                                    <h6 className='text-dark' >{item.content.product_name}</h6>
                                                                </Link>
                                                                <div className="text-main d-flex">
                                                                    {item.content.product_price === null ?
                                                                        <small className="text-main">Consultar preço</small>
                                                                        : numberWithCommas(item.content.product_price) + " €"}
                                                                    {Math.floor(item.content.product_oldprice) > 0 ? " - " + numberWithCommas(item.content.product_oldprice) + " €" : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="center">
                                                            <input type="number" onPaste={(e) => UP(item.code, e.target.value)}
                                                                onChange={(e) => UP(item.code, e.target.value)}
                                                                value={item.ammount * 1} className="form-control" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="center">
                                                            <button onClick={() => RM(item.code)} className="bg-main text-light btn"><Trash /> </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <div className="pd"></div>
                                    <div className="finish-shop">
                                        <div className="dets">
                                            <h3>Finalizar compra</h3>
                                        </div>
                                        <hr />
                                        <div className="dets">
                                            <p>
                                                Apos confirmar a compra dos produtos listados no carrinho,
                                                a nossa equipe de vendas entrara em contacto para dar continuidade ao procedimento.
                                            </p>
                                        </div>
                                        <hr />
                                        <div className="dets">
                                            <button className="col mt-4 btn text-light bg-main" onClick={() => OrderProducts(Data)}>Confirmar compra</button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div> :
                <div> 
                </div>
            }
            <NewsLetter />
            <Footer />
        </div>
    )
}

export default Dashboard