import React from 'react'
import Carousel from "react-multi-carousel";
import ImageLazyLoading from '../Components/ImageLazyLoading';
import { Link } from 'react-router-dom';

function HomeProductsSlider() {

  const ProducstData = [
    {
      name: "Compressor Elgi Serie En 5x com secador",
      link: "/products?brand=Elgi",
      image: require("../../Assets/Images/b4.jpg")
    },
    {
      name: "Compressor COMPRAG SERIE F",
      link: "products?brand=Comprag",
      image: require("../../Assets/Images/b5.jpg"),
    },
  ];

  const ProductsData2 = [
    {
      name: "Acessórios push-on para ar comprimido",
      link: "/products?brand=Aignep",
      image: "https://5.imimg.com/data5/OA/HM/YX/SELLER-1488914/push-on-fittings-for-compressed-air.PNG"
    },
  ]


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };


  return (
    <div className='wo-main-carousel'>
      <div className="carousel">
        <Carousel
          swipeable={true} draggable={false} showDots={true}
          responsive={responsive} ssr={true}
          infinite={true} autoPlay={true}
          autoPlaySpeed={4000} keyBoardControl={true} customTransition="all .5" transitionDuration={6000}
          containerclassName="carousel-container" removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListclassName="custom-dot-list-style" itemclassName="carousel-item-padding-40-px" >

          {ProducstData.map((item, index) => {
            return (
              <Link to={item.link}>
                <div className='carousel-element' key={index + 1}>
                  <ImageLazyLoading source={item.image} height={500} />
                  <div className="over-info">
                    <div className="name">{item.name}</div>
                    <div className="price-tag">Consultar preço</div>
                  </div>
                </div>
              </Link>
            )
          })
          }
        </Carousel>
      </div>
      <div className="carousel-vertical">
        <div className="carousel">
          <Carousel
            swipeable={true} draggable={false} showDots={true}
            responsive={responsive} ssr={true}
            infinite={true} autoPlay={true}
            autoPlaySpeed={4500} keyBoardControl={true} customTransition="all .5" transitionDuration={7000}
            containerclassName="carousel-container" removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListclassName="custom-dot-list-style" item className="carousel-item-padding-40-px"> 
            {ProductsData2.map((item, index) => {
              return (
                <Link to={item.link}>
                  <div className='carousel-element' key={index + 1}>
                    <ImageLazyLoading source={item.image} height={500} />
                    <div className="over-info">
                      <div className="name">{item.name}</div>
                      <div className="price-tag">Consultar preço</div>
                    </div>
                  </div>
                </Link>
              )
            })}
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default HomeProductsSlider