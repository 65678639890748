import React from 'react'
import { FaPhone } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { RiMailSendFill } from "react-icons/ri";


function ContactArea() {
    return (
        <div>
            <section className="contact">
                <div className="row">
                    <div className="col-lg-5 ct">
                        <h1 className="title">Informações de contacto </h1>
                        <p className="desc">Você tem um projeto ou uma ideia que gostaria de colaborar conosco? Vamos entrar em contato !</p>
                        <li>
                            <div className="icon"><RiMailSendFill /></div>
                            <div className="block">
                                <span>Emails:</span>
                                <p>comercial@hightech-airer.pt</p>
                                <p>geral@hightech-airer.pt</p>
                            </div>
                        </li>
                        <li>
                            <div className="icon"><FaMapMarkerAlt /></div>
                            <div className="block">
                                <span>Nosso endereço:</span>
                                <p>Portugal - Lisboa - R. Poe. José Gomes Ferreira 6 / 8, 2860-593 Moita</p>
                            </div>
                        </li>
                        <li>
                            <div className="icon"><FaPhone /></div>
                            <div className="block">
                                <span>Telefones :</span>
                                <p>+21 444 7020</p>
                            </div>
                        </li>
                    </div>
                    <div className="col-lg-7">
                    <div className="map">
                    <div className="mapouter">
                        <div className="gmap_canvas">
                            <iframe className="gmap_iframe" frameborder="0" scrolling="no"
                                marginheight="0" marginwidth="0"
                                src="https://www.google.com/maps/embed/v1/place?q=José+Gomes+Ferreira+6+8,+2860-593+Moita+hightech&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8">
                            </iframe>
                        </div>
                    </div>
                      </div>
                    </div>
                </div>
                
            </section>
        </div>
    )
}

export default ContactArea