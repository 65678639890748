import React, { useState } from 'react';
import ImageLazyLoading from '../ImageLazyLoading';
import { TrashOutline } from 'react-ionicons'
import { RemoveProductFromCart, UpdateProductAmountFromCart } from '../../../Functions/CartFunctions';


function ProductCartComponent({ data }) {
    const [ProductAmount, setProductAmount] = useState(1);

     function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    
  async function RM(A) { 
    RemoveProductFromCart(A); 
  }

  function truncateString(str, maxLength) {
    if (str.length > maxLength) {
        return str.slice(0, maxLength - 3) + '...';
    }
    return str;
  }
  
 
  async function UP(A, B) {
    UpdateProductAmountFromCart(A, B);   
  } 

    return (
        <div className='product-cart-el'>
            <div className="pr-box">
                <div className="image">
                    <ImageLazyLoading source={data.images.length > 0 ? data.images[0].file_name : "https://img.freepik.com/premium-vector/modern-flat-icon-landscape_203633-11062.jpg"} /> 
                </div>
                <div className="details">
                    <h3>{truncateString(data.content.product_name,  25)}</h3>
                    <span>Marca : {data.content.product_brand}</span>
                    <div className="price"><h5>
                            {data.content.product_price === null ?
                                <small className="text-main">Consultar preço</small>
                                : numberWithCommas(data.content.product_price) + " €"}
                            {Math.floor(data.content.product_oldprice) > 0 ? " - " + numberWithCommas(data.content.product_oldprice) + " €" : ""}
                        </h5>
                    </div> 
                    <div className="actions">
                        <input type="number"   onPaste={(e) => UP(data.code, e.target.value)}  onChange={(e) => UP(data.code, e.target.value)}
                        value={data.ammount * 1}  className="form-control" /> 
                        <div className="delete" onClick={() => RM(data.code)}   ><button className='btn'><TrashOutline /></button></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductCartComponent