import React from 'react'
import ServerUrl from '../../Pages/ServerUrl';
import axios from "axios"
import { toast } from 'react-toastify';

function NewsLetter() {
    const [Email, setEmail] = React.useState(null);
    const ReceiverEmail = "comercial@hightech-airer.pt";

    const NewsLetterSumbit = async(e)=>{
        e.preventDefault();
        try {
          if (Email !== null) {
              await axios.post(ServerUrl()+"sendemailfornewsletter", {email_receiver:ReceiverEmail, email_sender:Email});
              setEmail(null);
              toast.success('Subscrição efectuada com sucesso !', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
          } else { 
            toast.error('Lamentamos mas não foi possivel executar está ação !', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
          } 
        } catch (error) {
            console.clear();
            console.log(error);
            toast.error('Lamentamos mas não foi possivel executar está ação !', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    } 
     

  return (
    <div>
    <div className="newsletter">
        <div className="over">
            <div className="flex box">
                <div className="icon fa fa-at"></div>
                <div className="block">
                    <h4>SUBSCREVA PARA MAIS NOTICIAS</h4>
                    <p>Obtenha todas as informações mais recentes sobre eventos, vendas e ofertas.</p>
                </div>
            </div>
            <div className="box">
                <form onSubmit={NewsLetterSumbit} className="flex" id="newsletter">
                    <input onChange={(e)=>setEmail(e.target.value)} onPaste={(e)=>setEmail(e.target.value)} 
                    type="email" required className="form-control" placeholder="Escreva o seu email"/>
                    <button type='submit' className="btn text-light">SUBSCREVA</button>
                </form>
            </div>
        </div>
        </div>
    </div>
  )
}

export default NewsLetter