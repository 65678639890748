import React from 'react'
import NewsLetter from '../../PagesComponents/Components/NewsLetter'
import Footer from '../../PagesComponents/Components/Footer'
import Navbar from '../../PagesComponents/Main/Navbar'

function ForgotPassword() {
    document.title = "Resetar senha"
    const [Email, setEmail] = React.useState(null);
    return (
        <div>
            <Navbar Active={null} />
            <div className="account">
          <div className="signin-box"> 
              <div className="signin-container">
                  <div className="box active">
                      <h2>Recuperar senha</h2>
                        <form action="" id="signincustomer"> 
                        <div className="form-group">
                            <label for="formGroupExampleInput">Email</label>
                            <input type="email" className="form-control" name="namec" 
                             onChange={(e)=>setEmail(e.target.value)}  onPaste={(e)=>setEmail(e.target.value)}
                            min={8} minLength={8}  placeholder="EX: Hightech@domain.pt"   />
                          </div> 
                            <button className="btn btn-signin">Enviar solicitação</button>  
                        </form>
                  </div> 
              </div>
          </div>
      </div> 
            <NewsLetter />
            <Footer />
        </div>
    )
}

export default ForgotPassword