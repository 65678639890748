 
function ServerUrl() {
  return "https://htmarketserver.vercel.app/"   // "http://localhost:6500/" ;
}

 
export function ClientUrl() {
  return "https://htmarkt.hightech-airer.pt/" ;
}


export default ServerUrl
