import React, { useEffect, useState } from 'react'
import ProductCard from '../Components/ProductComponents/ProductCard';
import ImageLazyLoading from '../Components/ImageLazyLoading';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ServerUrl from '../../Pages/ServerUrl';
import Loader from '../Loader';

function FeaturedProducts() {
    const [LoadedProducts, setLoadedProducts] = useState([]);

    async function loadData() { 
        try { 
                const [products1] = await Promise.all([
                    axios.get(ServerUrl() + "htmarketgetallproducts")
                ]);
      
                let products = []
                products1.data.d1.forEach(el => {
                  let images = [];
                  products1.data.d2.forEach(img => {
                    if(img.file_product_code === el._id)  images.push(img); 
                  });
                  products.push({ content: el, images: images });
                });


              setLoadedProducts(products); 
              localStorage.setItem('products', JSON.stringify(products));  
         } catch (error) {
             console.log(error)
         } 
    }


    useEffect(() => {
        loadData();
    }, []);



    return (
        <div>
            <aside className="featured-products">
                <h1 className="title">Produtos em destaque</h1>
                <div className="products-container">
                    <div className="products-custom">
                        <div className="products-content">
                            {LoadedProducts.length <= 0 ?
                                <div className="center col">
                                    <div>
                                        <br /><br />
                                        <Loader />
                                    </div>
                                </div>
                                :
                                <>
                                    {LoadedProducts.slice(0, 10).map((item, index) => (
                                        <ProductCard data={item} key={index} />
                                    ))}
                                </>
                            }
                        </div>
                        <div className="block announce-block">
                            <section className="announce n1">
                                <a href="#"><ImageLazyLoading  source={require("../../Assets/Images/b2.png")}  alt="#" /></a>
                            </section>
                            <section className="announce-extra">
                                <a href="#"><ImageLazyLoading source="https://www.elgi.com/us/wp-content/uploads/2021/08/ELGi-NA_Website-banner-scaled.jpg" alt="#" /></a>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="post-banner">
                    <div className="item">
                        <Link>
                            <ImageLazyLoading source={require("../../Assets/Images/b3.png")}  />
                        </Link>
                    </div>
                </div>
                <br />
                <div className="products-content">
                    {LoadedProducts.length <= 0 ?
                        <div className="center col">
                            <div>
                                <br /><br />
                                <Loader />
                            </div>
                        </div>
                        :
                        <>
                            {LoadedProducts.slice(12, 24).map((item, index) => (
                                <ProductCard data={item} key={index} />
                            ))}
                        </>
                    }
                </div>
            </aside>
        </div>
    )
}

export default FeaturedProducts