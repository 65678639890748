import React from 'react'
import NewsLetter from '../../PagesComponents/Components/NewsLetter'
import Footer from '../../PagesComponents/Components/Footer'
import Navbar from '../../PagesComponents/Main/Navbar'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react';
import axios from 'axios'; 
import { toast } from 'react-toastify';
import ServerUrl, { ClientUrl } from '../ServerUrl'

function Signup() {
  document.title = "Cadastrar";
  const [Username, setUsername] = React.useState(null);
  const [Email, setEmail] = React.useState(null);
  const [Password, setPassword] = React.useState(null);
  const CurrentUser = localStorage.getItem("useremail");
  
  const navigate = useNavigate();
  const GetUserData = async()=>{
    let btn = document.querySelectorAll(".d-none .rfs")
    for (let i = 0; i < btn.length; i++){ btn[i].click();}
    try {
      const response = await axios.get(ServerUrl()+"getcurrentuserdata/"+CurrentUser);  
      window.location.replace(ClientUrl()+"useraccount"); 
    } catch (error) {
      console.log("NOT LEGGEDIN...") 
    }
  } 



useEffect(() => {
  GetUserData();
}, []);



  async function PostData(e) {
    e.preventDefault();
    try {
        if (Username !== null && Email !== null && Password !== null) {
            const INPUTS =  { username: Username, email:Email, password: Password};     
            let response = await axios.post(ServerUrl() + "htmarketuserregister", INPUTS); 
            toast.success('Conta criada com sucesso !', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light", 
          });
        } else {
          toast.error('Preencha corretamente os campos !', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light", 
        });
        } 
    } catch (error) {
       alert("error")
       console.clear()
       console.log(error)
    }
}

 


  return (
    <div>
      <Navbar Active={null} />
      <div className="account">
        <div className="signin-box"> 
            <div className="signin-container">
                <div className="box active">
                    <h2>Registrar</h2>
                      <form action="" id="signincustomer" onSubmit={PostData} >
                        <div className="form-group">
                            <label for="formGroupExampleInput">Nome completo</label>
                            <input type="text" className="form-control" name="namec" 
                             onChange={(e)=>setUsername(e.target.value)}  onPaste={(e)=>setUsername(e.target.value)}
                            min={8} minLength={8}  placeholder="EX: André Silva"   />
                          </div>
                          <div className="form-group">
                            <label for="formGroupExampleInput">Email</label>
                            <input type="email" className="form-control" name="emailc" required 
                               onChange={(e)=>setEmail(e.target.value)}  onPaste={(e)=>setEmail(e.target.value)}
                            placeholder="EX: hightech-airer@gmail.com"/>
                          </div>
                          <div className="form-group">
                            <label for="formGroupExampleInput2">Password</label>
                            <input type="password" className="form-control" name="passwordc"
                               onChange={(e)=>setPassword(e.target.value)}  onPaste={(e)=>setPassword(e.target.value)}
                            min={8} minLength={8} required  placeholder="EX: 123Hightech#"/>
                          </div>
                          <div className="d-block justify-content-space-between">
                            <div className="col-lg-8">
                                <div className="custom-control custom-checkbox ml-0">
                                    <input type="checkbox" className="custom-control-input ml-0" name="" id="c2" value=""/>
                                    <label className="custom-control-label" for="c2"> <div className="ml-2">Lembrar sempre</div> </label>
                                </div>
                              </div>
                              <div className='mt-2 d-flex'>
                                  <a href="#">Já tem uma conta  ?</a> 
                                  <span className="ml-2"><Link className='text-secondary' to={"/signin"}>login</Link> </span>
                              </div>
                          </div>
                          <button className="btn btn-signin">Cadastrar</button> 
                      </form>
                </div> 
            </div>
        </div>
    </div> 
      <NewsLetter />
      <Footer />
    </div>
  )
}

export default Signup