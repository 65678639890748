import React, { useState } from 'react'
import ProductCard from './ProductComponents/ProductCard';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Link, useLocation } from 'react-router-dom';
import { Form, Offcanvas } from "react-bootstrap";
import { BsFilterCircle } from "react-icons/bs";
import axios from "axios"
import ServerUrl from '../../Pages/ServerUrl';
import { useEffect } from 'react';
import Loader from "../Loader"
import ReactPaginate from 'react-paginate';
import ImageLazyLoading from './ImageLazyLoading';
import AignepProducts from './AignepProducts';

function SearchComponents() {
    const [ActiveCategorie, SetActiveCategorie] = useState({})
    const [selectedSubCategory, SetselectedSubCategory] = useState({});
    const [show, setShow] = useState(false);
    const [showBox, setShowBox] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [ShowSelectedBrand, setShowSelectedBrand] = useState(null);

    const [showMenu, setShowMenu] = useState(false);
    const [LoadedCategories, setLoadedCategories] = useState([]);
    const [LoadedSeries, setLoadedSeries] = useState([]);
    const [LoadedProducts, setLoadedProducts] = useState(null);
    const [ProductSerie, setProductSerie] = useState(null);
    const [FilterPrice, setFilterPrice] = useState(null);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const KeySearch = searchParams.get('key');
    const CatSearch = searchParams.get('cat');
    const [BrandSearch, setBrandSearch] = useState(searchParams.get('brand'));

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    async function getProductSeries() {
        try {   
            let response = await axios.get(ServerUrl() + "htmarketgetallproductsseriesbysub/" + 1); 
            setLoadedSeries(response.data)
            console.log("#####################################*****");
            console.log(response.data);
            console.log("#####################################****");
        } catch (error) {
            console.clear()
            console.log(error)
        }
    }



    function ChangeSerieAndCat(a, b, c) {
        setProductSerie(a);
        SetActiveCategorie({ value: b, show: c });
        SetselectedSubCategory({});
        setBrandSearch(null)
    }


    function ChangeSerieAndCatSub(a, b, c) {
        SetActiveCategorie({ value: null, show: c });
        SetselectedSubCategory({ id: a, name: b, code: a });
        setBrandSearch(null);
    }


    const LoadNewData = async () => {
        try {
            if (BrandSearch !== null && BrandSearch !== undefined && BrandSearch !== "" && BrandSearch.toLowerCase() === "aignep") {
                setShowBox(true);
                setLoadedProducts([1]);
            } else {
                setShowBox(false);
                const [response, products1] = await Promise.all([
                    axios.get(ServerUrl() + "htmarketgetallcategories"),
                    axios.get(ServerUrl() + "htmarketgetallproducts"),
                ]);

                let products = []
                products1.data.d1.forEach(el => {
                    let images = [];
                    products1.data.d2.forEach(img => {
                        if (img.file_product_code === el._id) images.push(img);
                    });
                    products.push({ content: el, images: images });
                });

                let result = []
                for (let i = 0; i < response.data.length; i++) {
                    let subs = [];
                    for (let a = 0; a < response.data[i].subcategories.length; a++) {
                        subs.push({ name: response.data[i].subcategories[a].subcategorie_name, id: response.data[i].subcategories[a].subcategorie_code });
                    }
                    result.push({ name: response.data[i].info.categorie_title, id: response.data[i].info._id, subcategories: subs })
                }

                localStorage.setItem('products', JSON.stringify(products));
                localStorage.setItem('categories', JSON.stringify(result));
            }
        } catch (error) {

        }
    }



    async function loadData() {
        setLoadedProducts(null);

        if (BrandSearch !== null && BrandSearch !== undefined && BrandSearch !== "" && BrandSearch.toLowerCase() === "comprag") setShowSelectedBrand(1);
        if (BrandSearch !== null && BrandSearch !== undefined && BrandSearch !== "" && BrandSearch.toLowerCase() === "elgi") setShowSelectedBrand(2);
        if (BrandSearch !== null && BrandSearch !== undefined && BrandSearch !== "" && BrandSearch.toLowerCase() === "infinity") setShowSelectedBrand(3);
        if (BrandSearch !== null && BrandSearch !== undefined && BrandSearch !== "" && BrandSearch.toLowerCase() === "jork") setShowSelectedBrand(4);
        if (BrandSearch !== null && BrandSearch !== undefined && BrandSearch !== "" && BrandSearch.toLowerCase() === "aignep") setShowSelectedBrand(5);
        if (BrandSearch !== null && BrandSearch !== undefined && BrandSearch !== "" && BrandSearch.toLowerCase() === "bigiessie") setShowSelectedBrand(6);

        try {
            const [response, products1] = await Promise.all([
                axios.get(ServerUrl() + "htmarketgetallcategories"),
                axios.get(ServerUrl() + "htmarketgetallproducts"),
            ]);

            let products = []
            products1.data.d1.forEach(el => {
                let images = [];
                products1.data.d2.forEach(img => {
                    if (img.file_product_code === el._id) images.push(img);
                });
                products.push({ content: el, images: images });
            });


            let result = []
            for (let i = 0; i < response.data.length; i++) {
                let subs = [];
                for (let a = 0; a < response.data[i].subcategories.length; a++) {
                    subs.push({
                        name: response.data[i].subcategories[a].subcategorie_name,
                        _id: response.data[i].subcategories[a]._id,
                        id: response.data[i].subcategories[a].subcategorie_code
                    });
                }
                result.push({ name: response.data[i].info.categorie_title, id: response.data[i].info._id, subcategories: subs })
            }


            getProductSeries();

            console.clear();
            console.log(products);

            setLoadedCategories(result);
            FilteredProducts(products, ((ActiveCategorie.value !== null && ActiveCategorie.value !== undefined) ? ActiveCategorie.value : CatSearch),
                FilterPrice, BrandSearch, KeySearch, ProductSerie, selectedSubCategory);
            localStorage.setItem('products', JSON.stringify(products));
            localStorage.setItem('categories', JSON.stringify(result));


        } catch (error) {
            console.log(error)
        }
    }

    const FilteredProducts = (DT, cat, price, brand, key, serie, subcat) => {
        let FilteredData = (Array.isArray(DT) === true ? DT : []);


        console.log("*****************************************");
        console.log(DT);
        console.log("****************************************");


        if (FilteredData.length > 0) {
            if (brand !== undefined && brand !== null && brand !== "") {
                let KeysFilters1 = [];
                if (brand.split("").length > 2) {
                    for (let i = 0; i < FilteredData.length; i++) {
                        if (FilteredData[i].content.product_brand.toLowerCase() === brand.toLowerCase()) {
                            KeysFilters1.push(FilteredData[i]);
                        }
                    }
                    FilteredData = KeysFilters1;
                }
            }

            function containsObject(obj, list) {
                var i;
                for (i = 0; i < list.length; i++) {
                    if (list[i] === obj) {
                        return true;
                    }
                }
                return false;
            }

            // search by categorie
            if (subcat.name === undefined || subcat.name === null) {
                if (cat !== undefined && cat !== null && cat !== "" && cat !== "null" && cat.split("").length >= 4) {
                    let KeysFilters0 = [];
                    for (let i = 0; i < FilteredData.length; i++) {
                        if (FilteredData[i].content.product_categorie === cat) {
                            if (containsObject(FilteredData[i], KeysFilters0) === false) {
                                KeysFilters0.push(FilteredData[i]);
                            }
                        }
                    }
                    FilteredData = KeysFilters0;
                }
            }


            // search by subcategorie
            if (subcat.code !== undefined && subcat.code !== null) {
                let KeysFilters0 = [];
                for (let i = 0; i < FilteredData.length; i++) {
                    console.log("Out = ", FilteredData[i].content.product_code * 1 === subcat.code * 1)
                    if (FilteredData[i].content.product_code * 1 === subcat.code * 1) {
                        if (containsObject(FilteredData[i], KeysFilters0) === false) {
                            KeysFilters0.push(FilteredData[i]);
                        }
                    }
                }
                FilteredData = KeysFilters0;
            }


            // search by price
            if (price !== undefined && price !== null && price !== "") {
                let KeysFilters0 = [];
                for (let i = 0; i < FilteredData.length; i++) {
                    if (FilteredData[i].content.product_price !== null) {
                        if (FilteredData[i].content.product_price <= price) {
                            KeysFilters0.push(FilteredData[i]);
                        }
                    }
                }
                FilteredData = KeysFilters0;
            }


            //  Get by serie
            if (serie !== undefined && serie !== null && serie !== "") {
                console.clear()
                let KeysFilters2 = [];
                if (serie.split("").length > 2) {
                    for (let i = 0; i < FilteredData.length; i++) {
                        console.log(FilteredData[i].content.product_serie  ,  serie );
                        if (FilteredData[i].content.product_serie  === serie ) {
                            KeysFilters2.push(FilteredData[i]);
                        }
                    }
                    FilteredData = KeysFilters2;
                }
            }

            // search by keys
            if (key !== undefined && key !== null && key !== "") {
                let KeysFilters = [];
                if (key.split("").length > 2) {
                    for (let i = 0; i < FilteredData.length; i++) {
                        if (FilteredData[i].content.product_name.toLowerCase().includes(key.toLowerCase()) ||
                            FilteredData[i].content.product_description.toLowerCase().includes(key.toLowerCase())) {
                            KeysFilters.push(FilteredData[i]);
                        }
                    }
                }
                FilteredData = KeysFilters;
            }
        }




        console.log("tOTAL IS = ", DT);
        setLoadedProducts(FilteredData);
    }



    useEffect(() => {
        loadData();
    }, [KeySearch, CatSearch, BrandSearch, FilterPrice, ShowSelectedBrand, ProductSerie, selectedSubCategory]);


    function Items({ currentItems }) {
        return (
            <>
                {currentItems &&
                    currentItems.map((item) => (
                        <ProductCard data={item} key={item.id} />
                    ))}
            </>
        );
    }

    function PaginatedItems({ itemsPerPage }) {
        let DTO = Array.isArray(LoadedProducts) === true ? LoadedProducts : [];

        const [itemOffset, setItemOffset] = useState(0);
        const endOffset = itemOffset + itemsPerPage;
        const currentItems = DTO.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(DTO.length / itemsPerPage);

        const handlePageClick = (event) => {
            const newOffset = (event.selected * itemsPerPage) % DTO.length;
            setItemOffset(newOffset);
        };

        return (
            <>
                <Items currentItems={currentItems} />
                <div className="pagination-area">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=" >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< "
                        renderOnZeroPageCount={null}
                    />
                </div>
            </>
        );
    }

    const FilterBrand = (a, b) => {
        setBrandSearch(a);
        setShowSelectedBrand(b);
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function ClearFilters() {
        let inputs = document.querySelectorAll("input");
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].checked = false
        }
        setFilterPrice(null)
        setBrandSearch(null)
        setProductSerie();
        SetActiveCategorie({});
        SetselectedSubCategory({});
        setBrandSearch(null)
    }



    return (
        <div>
            <div className="search">
                <div className={showBox ? "filters sr d-none" : "filters sr"}>
                    <div className="top-filter"><Link to="/" className='text-main'><span>Inicio</span></Link> / produtos</div>
                    <form action="" id="filtersearch">
                        <div className="space">
                            <h4>Filtros</h4>
                        </div>
                        <div  >
                            {(KeySearch !== null && KeySearch !== undefined && KeySearch !== "" && KeySearch !== "null") ? <div className="search-tag"><div className="text">{KeySearch}</div></div> : <></>}
                        </div>
                        <hr />
                        <br />
                        <h5 className="title">Marcas</h5>
                        <ul>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" checked={ShowSelectedBrand === 1 ? true : false}
                                    onChange={() => FilterBrand("comprag", 1)} name="comprag" id="comprag" value="comprag" />
                                <label className="custom-control-label ml-2" for="comprag">Comprag</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" checked={ShowSelectedBrand === 2 ? true : false}
                                    onChange={() => FilterBrand("elgi", 2)} name="elgi" id="elgi" value="elgi" />
                                <label className="custom-control-label ml-2" for="elgi"> Elgi</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" checked={ShowSelectedBrand === 3 ? true : false}
                                    onChange={() => FilterBrand("infinity", 3)} name="infinity" id="infinity" value="infinity" />
                                <label className="custom-control-label ml-2" for="infinity">Infinity</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" checked={ShowSelectedBrand === 4 ? true : false}
                                    onChange={() => FilterBrand("jork", 4)} name="jork" id="jork" value="jork" />
                                <label className="custom-control-label ml-2" for="jork">Jork </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" checked={ShowSelectedBrand === 5 ? true : false}
                                    onChange={() => FilterBrand("aignep", 5)} name="aignep" id="aignep" value="aignep" />
                                <label className="custom-control-label ml-2" for="aignep"> Aignep</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" checked={ShowSelectedBrand === 6 ? true : false}
                                    onChange={() => FilterBrand("bigiessie", 6)} name="bigiessie" id="bigiessie" value="bigiessie" />
                                <label className="custom-control-label ml-2" for="bigiessie">Bigiessie</label>
                            </div>
                        </ul>
                        <hr />
                        <h5 className="title">Preços</h5>
                        <ul>
                            {[10, 50, 100, 200, 500, 1000, 2500, 3500, 11500, 23500].map((item, index) => (
                                <div className="custom-control custom-checkbox">
                                    <Form.Check onChange={() => setFilterPrice(item * 4)} inline
                                        label={"€ " + numberWithCommas(Math.floor(item * 4))} name="group1" type={"radio"} id={`radio-${index}`} />
                                </div>
                            ))}
                        </ul>
                        <hr />
                    </form>
                    <button className="btn bg-main text-light mt-4" onClick={() => ClearFilters()} >Limpar Filtros</button>
                </div>
                <div className="pdl sr"></div>
                <div className="products-container">
                    {LoadedProducts === null ?
                        <div className="center">
                            <div>
                                <br /><br />
                                <Loader />
                            </div>
                        </div>
                        :
                        <aside>

                            <div>
                                <div className="space ">
                                    <div>
                                        <h3 className='bold'>Produtos encomtrados
                                            {(BrandSearch !== undefined && BrandSearch !== null && BrandSearch !== "") ? <strong className="text-main uper"> ({BrandSearch})</strong> : ""}
                                        </h3>
                                        <h5 className="text-main mt-2 mb-2">  </h5>
                                    </div>
                                    <div className="d-none">
                                        <button onClick={handleShow} className='btn d-none mb-4 mt-2 filter-btn bg-main'><BsFilterCircle /> Aplicar filtros</button>
                                    </div>
                                    <div className="flex">
                                        {!showBox ? <span>{LoadedProducts.length} produtos encomtrados</span> : <span></span>}
                                    </div>
                                </div>
                                {showBox ?
                                    <AignepProducts /> :
                                    <section>
                                        <div className="mt-2 mb-2 ">
                                            <div className="search-by-types">
                                                {LoadedCategories.map((item, index) => (
                                                    <div>
                                                        {index === 0 ?
                                                            <div className='d-flex'>
                                                                <Dropdown key={index}>
                                                                    <Dropdown.Toggle className={(ActiveCategorie.show === item.id || item.id === CatSearch) ? "btn mt-2 active" : "btn mt-2"} id="dropdown-basic">{item.name}</Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <div onClick={() => ChangeSerieAndCat(null, item.id, item.id)}>
                                                                            <Dropdown.Item href="#">Exibir tudo</Dropdown.Item>
                                                                        </div> 
                                                                        {item.subcategories.map((Cat, Catindex) => (
                                                                            <div onClick={() => ChangeSerieAndCatSub(Cat.id, Cat.name, item.id)}>
                                                                                <Dropdown.Item className={(selectedSubCategory.id === Cat.id) ? "bg-main text-light" : ""} href="#">{Cat.name}</Dropdown.Item>
                                                                            </div>
                                                                        ))}  
                                                                        {LoadedSeries.map((serie, ind) => {
                                                                            return (
                                                                                <div key={ind} onClick={() => setProductSerie(serie._id)}>
                                                                                    <Dropdown.Item className={(ProductSerie === serie._id) ? "bg-main text-light" : ""} href="#">{serie.serie_name}</Dropdown.Item>
                                                                                </div>
                                                                            )
                                                                        })}  
                                                                     </Dropdown.Menu>
                                                                </Dropdown>  
                                                            </div>
                                                            :
                                                            <>
                                                             <Dropdown key={index}>
                                                                <Dropdown.Toggle className={(ActiveCategorie.show === item.id || item.id === CatSearch) ? "btn mt-2 active" : "btn mt-2"} id="dropdown-basic">{item.name}</Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <div onClick={() => ChangeSerieAndCat(null, item.id, item.id)}>
                                                                            <Dropdown.Item href="#">Exibir tudo</Dropdown.Item>
                                                                        </div> 
                                                                        {item.subcategories.map((Cat, Catindex) => (
                                                                            <div onClick={() => ChangeSerieAndCatSub(Cat.id, Cat.name, item.id)}>
                                                                                <Dropdown.Item className={(selectedSubCategory.id === Cat.id) ? "bg-main text-light" : ""} href="#">{Cat.name}</Dropdown.Item>
                                                                            </div>
                                                                        ))}  
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </>
                                                        }
                                                    </div>
                                                ))} 
                                            </div>
                                        </div>
                                        {LoadedProducts.length >= 1 ?
                                            <div className="products-content">
                                                <PaginatedItems itemsPerPage={20} />
                                            </div>
                                            :
                                            <div className="center">
                                                <div className="empty-area">
                                                    <ImageLazyLoading source={require("../../Assets/Images/empty.png")} />
                                                    <br />
                                                    <h1>Lamentamos mas  não foi possivel emcontrar  produtos durante a  pesquisa.</h1>
                                                </div>
                                            </div>
                                        }
                                    </section>
                                }
                            </div>

                        </aside>
                    }
                </div>
            </div>
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="bold-title">Aplicar filtros</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <form className='space-search' action="#">
                        <div className="filters scroll-view col sr1">
                            <form action="" id="filtersearch">
                                <div className="col">
                                    <hr />
                                    <br />
                                    <h5 className="title">Marcas</h5>
                                    <ul>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" name="" id="" value="" />
                                            <label className="custom-control-label ml-2" for="">Comprag</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" name="" id="" value="" />
                                            <label className="custom-control-label ml-2" for=""> Elgi</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" name="" id="" value="" />
                                            <label className="custom-control-label ml-2" for="">Infinity</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" name="" id="" value="" />
                                            <label className="custom-control-label ml-2" for="">Jork </label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" name="" id="" value="" />
                                            <label className="custom-control-label ml-2" for=""> Aignep</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" name="" id="" value="" />
                                            <label className="custom-control-label ml-2" for="">Bigiessie</label>
                                        </div>
                                    </ul>
                                    <hr />
                                    <h5 className="title">Preços</h5>
                                    <ul>
                                        {[1, 345, 56, 7788, 8, 7, 8, 99, 9, 8].map((item, index) => (
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="" id="" value="" />
                                                <label className="custom-control-label ml-2" for=""> € {Math.floor(item * 1)}.00</label>
                                            </div>
                                        ))}
                                    </ul>
                                    <hr />
                                </div>
                            </form>
                        </div>
                    </form>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}






export default SearchComponents