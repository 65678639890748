import React from 'react'
import Logo from './Logo'
import { Link } from 'react-router-dom'
import { FaFacebook } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { RiInstagramFill } from "react-icons/ri";
import { FaYoutube } from "react-icons/fa6";
import { useEffect } from 'react';


function Footer() {

    let Year = new Date().getFullYear();

   useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
        <footer>
            <div className="links">
                <div className="box bxb">
                    <Link to="/"><Logo type={1} /></Link>
                    <ul>
                    <li className="cplocation">Portugal - Lisboa - R. Poe. José Gomes Ferreira 6 / 8, 2860-593 Moita</li>
                    <li className="cpphone">+21 444 7020</li>
                    <li><a to="mailto:#comercial@hightech-airer.pt" className="cpemail">comercial@hightech-airer.pt</a></li> 
                    <li><a to="mailto:#geral@hightech-airer.pt" className="cpemail">geral@hightech-airer.pt</a></li>
                        <div className="flex social-links">
                           <li><a href="https://www.facebook.com/hightechmoita?locale=pt_PT" target='_blank' className="icon"><FaFacebook /></a></li>
                           <li><a href="https://pt.linkedin.com/company/hightech-airer-solu%C3%A7%C3%B5es-%C3%A0-sua-medida?trk=public_profile_topcard-current-company" target='_blank' className="icon"><FaLinkedin /></a></li>
                       </div>
                    </ul>
                </div>
                <div className="box">
                    <h5>Empresa</h5>
                    <ul>
                        <li><Link to="https://hightech-airer.pt/about">Sobre nós</Link></li> 
                        <li><Link to="/contacts">Contatos</Link></li>
                        <li><Link to="/products">Produtos</Link></li>
                        <li><Link to="/dashboard">Dashboard</Link></li>
                    </ul>
                </div>
                <div className="box">
                    <h5>Outros links</h5>
                    <ul>
                        <li><Link to="/signin">Conta cliente</Link></li> 
                        <li><Link to="/signup">Criar conta cliente</Link></li>  
                        <li><Link to="https://hightech-airer.pt/terms_and_conditions">Termos e Condições</Link></li>
                        <li><Link to="https://hightech-airer.pt/privacy_policy">Política de privacidade</Link></li>
                    </ul>
                </div> 
            </div> 
            <div className="copyright">
                <div><p>Copyright © { Year } HTmarkt loja online. All Rights Reserved. </p></div>
            </div>
      </footer>
    </div>
  )
}

export default Footer