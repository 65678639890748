import React from 'react'
import ImageLazyLoading from '../ImageLazyLoading'
import { SearchOutline, HeartOutline } from 'react-ionicons'
import { MdBalance } from "react-icons/md";
import { TbBasketMinus } from "react-icons/tb";
import { Link } from 'react-router-dom';
import { ImEye } from "react-icons/im";
import { LuShoppingCart } from "react-icons/lu";
import parse from 'html-react-parser';
import { AddProductToCart, RemoveProductFromCart } from '../../../Functions/CartFunctions';


function ProductCard({data}) {

 

function truncateString(str, maxLength) {
  if (str.length > maxLength) {
      return str.slice(0, maxLength - 3) + '...';
  }
  return str;
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

  return (
    <div className="product-box">
    <div className="image">
        <div className="img1">
          <ImageLazyLoading  source={data.images.length > 0 ? data.images[0].file_name : "https://img.freepik.com/premium-vector/modern-flat-icon-landscape_203633-11062.jpg"  }  />
        </div>
        <div className="img2">
           <ImageLazyLoading  source={data.images.length > 0 ? (data.images.length > 2 ? data.images[2].file_name : (data.images.length > 1 ? data.images[1].file_name : data.images[0].file_name)) : "https://img.freepik.com/premium-vector/modern-flat-icon-landscape_203633-11062.jpg"  }  />
        </div>
         <div className="overlay">
          <div className="flex"> 
                <div onClick={()=>AddProductToCart(data.content._id ,1)} ><LuShoppingCart /> </div> 
                 <Link to={`/product_details?item=${data.content._id}`}><div className="fa fa-heart"><ImEye color='black' /></div></Link>  
            </div>
        </div>
    </div>
    <Link to={`/product_details?item=${data.content._id}`}>
       <div className="p-name">{truncateString(data.content.product_name,  40)}</div>
    </Link>
    <div className="description"><p>{truncateString(data.content.product_description , 30)}</p></div>
    <div className="flex">
        <div><h1>{data.content.product_price === null ?
         <small className="text-main">Consultar preço</small> 
        :  numberWithCommas(data.content.product_price)+ " €"}
         {Math.floor(data.content.product_oldprice) > 0 ?  " - "+numberWithCommas(data.content.product_oldprice)+ " €"  : ""}      </h1></div> 
    </div>
</div>
  )
}

export default ProductCard