
import React from 'react'
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

function DashboardSignin() {
  document.title = "Htmarkt - portal administrativo ";
  const [Email, setEmail] = React.useState(null);
  const [Password, setPassword] = React.useState(null);

  let UserData = {
    username: "geral.hightech@gmail.com",
    password: "123Hightech"
  }

  function Auth(e) {
    e.preventDefault();
    if (Email === UserData.username && Password === UserData.password) {
      localStorage.setItem("admusername", Email);
      localStorage.setItem("admpassword", Password);
      setTimeout(() => {
          window.location.reload();
      }, 100);
    } else {
      toast.error('Credenciais invalidas  !', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  return (
    <div className='bg-black auth-dash'>
      <div className="auth-box">
        <div className="box">
          <Form onSubmit={Auth} >
            <div className="title">Entrar</div>
            <p>Use as suas credencias para aceder ao portal administrativo do website htmarkt.</p>
            <div className="form-group">
              <label for="formGroupExampleInput">Email</label>
              <input type="email" className="form-control" name="namec"
                onChange={(e) => setEmail(e.target.value)} onPaste={(e) => setEmail(e.target.value)}
                min={8} minLength={8} placeholder="EX: Hightech@domain.pt" />
            </div>
            <div className="form-group mt-4">
              <label for="formGroupExampleInput">Password</label>
              <input type="password" className="form-control" name="password" required
                onChange={(e) => setPassword(e.target.value)} onPaste={(e) => setPassword(e.target.value)}
                placeholder="EX: senha#456" />
            </div>
            <button type='submit' className="btn bg-main mt-4 col-lg-12">Entrar</button>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default DashboardSignin