import React from 'react'
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';
import { GoSignOut } from "react-icons/go";
import { Globe } from 'react-ionicons';

function DashboardWrapper({title, content, active}) {
  document.title = "Dashboard - "+title; 


  function LogOut(){
    localStorage.clear();
    setTimeout(() => {
      window.location.reload();
   }, 100);
  }

  return (
    <div className='dashboard-area'>
        <div className="sidebar bg-black">
             <div className="user-info">
                <Avatar sx={{ bgcolor:"red", width:100, height:100}}>HM</Avatar>    
               <div className='text-left'>
                  <div className="name"><h3>Hightech store manager.</h3></div>  
                  <span className='text-secondary'>geral.hightech@gmail.com</span>
               </div>
            </div>    
            <div>
              <Link to={"/"} ><button className="btn bg-light text-main" ><Globe color={"red"} /> Visitar o site </button></Link>
                <button className="btn bg-main" onClick={()=>LogOut()} ><GoSignOut /> Terminar sessão</button>
            </div>
        </div>
        <div className="content-wrapper col">
            <div className="dash-header">
           
            </div>
             <div className="content col">
                {content}
             </div>
             <div className="footer-info">
                 
             </div>
        </div>
    </div>
  )
}

export default DashboardWrapper